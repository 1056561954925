.splash {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1500px;
}

.content {
  min-height: 100vh;
  padding: 24px 24px 72px;
  margin: 0 auto;
  max-width: 720px;
  box-sizing: content-box;
}

.skill-set {
  display: flex;
  gap: 8px;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.skill-set-item {
  background: #eee;
  padding: 6px 16px;
  border-radius: 3px;
  font-size: 15px;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  min-height: 36px;
}

.skill-set-item img {
  width: auto;
  height: 100%;
  max-height: 24px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
}

.footer img {
  width: 24px;
  height: 24px;
}
