.modal-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  width: 100%;
  max-width: 960px;
  margin: 48px;
  padding: 48px;
  border-radius: 8px;
  max-height: 100%;
  overflow: auto;
}

@media only screen and (max-width: 640px) {
  .modal {
    margin: 24px;
    padding: 24px;
  }
}
