video {
  height: 101%;
  aspect-ratio: 1;
  width: 100%;
}

.pet-projects-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  max-width: 100%;
  overflow: hidden;
}

.pet-projects-container > * {
  position: relative;
  aspect-ratio: 1/1;
}

.project-button {
  position: relative;
  padding: 0;
  background: transparent;
  overflow: hidden;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.4);
  display: none
}

.project-button:hover .overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  text-shadow: 0 0 12px black;
}

.active-project {
  display: grid;
  width: 100%;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
}

.active-project-description {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
}

.video-container {
  width: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 640px) {
  .active-project {
    grid-template-columns: 1fr;
  }

  .video-container {
    order : -1
  }

  .pet-projects-container {
    grid-template-columns: 1fr 1fr;
  }
}