* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  max-width: 100%;
  font-family: "Nimbus Sans L";
}

@font-face {
  font-family: "Nimbus Sans L";
  src: url(../public/fonts/NSL-regular.ttf);
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nimbus Sans L";
  src: url(../public/fonts/NSL-bold.ttf);
  font-weight: bold;
  font-display: swap;
}


@font-face {
  font-family: "Pokemon";
  src: url(../public/fonts/Pokemon.ttf);
  font-weight: normal;
  font-display: swap;
}

#root {
  width: 100vw;
  height: 100vh;
}

h2 {
  font-size: 64px;
  margin: 72px 0 24px;
  letter-spacing: -3px;
  border-bottom: 4px solid #0c151a;
  padding-bottom: 24px;
}

h3 {
  font-size: 24px;
  margin: 0 0 24px;
  letter-spacing: -1px;
  border-bottom: 2px solid #0c151a;
  padding-bottom: 24px;
}

h4 {
  font-size: 18px;
}

p {
  line-height: 1.5rem;
  letter-spacing: 0.2px;
  margin-bottom: 1.5rem;
}

button {
  all: unset;
  cursor: pointer;
}